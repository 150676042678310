<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">

        <!-- Searchbar Input -->
        <ion-toolbar v-if="showSearchBar">
          <ion-searchbar style="padding-top: 12px" mode="ios" ref="searchBarRef" v-model="searchKeyword" :placeholder="t('search')"
                        :cancel-button-text="t('cancel')" show-cancel-button="always" @ionCancel="showSearchBar = false"></ion-searchbar>
        </ion-toolbar>

        <!-- Page title & action buttons -->
        <ion-toolbar v-else>

          <ion-buttons slot="start">
            <ion-back-button default-href="/place-overview"></ion-back-button>
          </ion-buttons>

          <ion-title>{{ t('places') }}</ion-title>

          <ion-buttons slot="primary">
            <ion-button router-link="/browsed-places">
              <ion-icon slot="icon-only" :icon="timeOutline"></ion-icon>
            </ion-button>
            <ion-button router-link="/liked-places">
              <ion-icon slot="icon-only" :icon="bookmarksOutline"></ion-icon>
            </ion-button>
            <ion-button @click="onClickSearchIcon()">
              <ion-icon slot="icon-only" :icon="search"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>

        <!-- Place types filter -->
        <ion-toolbar>
          <ion-segment v-model="typeFilter" @ionChange="numOfVisibleItems = 20" scrollable>
            <ion-segment-button v-for="type in placeTypes" :key="type.id" :value="type.title">
              <ion-label style="margin-bottom: 6px">{{ getLocalizedStr(type, 'title', 'titleEn') }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <!-- District filter -->
        <ion-toolbar>
          <div class="horizontal-scroll">
            <ion-chip @click="selectedDistrict = 'all'" :outline="selectedDistrict != 'all'">
              <ion-label>{{ t('all') }} ({{ filterPlaces(typeFilter, 'all').length }})</ion-label>
            </ion-chip>
            <ion-chip v-for="district in filteredDistricts(typeFilter)" :key="district" @click="selectedDistrict = district.name"
                      :outline="selectedDistrict != district.name">
              <ion-label>{{ district.name }} ({{ district.numOfInfo }})</ion-label>
            </ion-chip>
          </div>
        </ion-toolbar>

        <!-- Sorting by metric -->
        <ion-toolbar style="--min-height: 32px">
          <ion-row v-show="!isSelectingComparingPlaces">
            <ion-col size-xs="9">
              <ion-item lines="none">
                <ion-icon slot="start" :icon="swapVerticalOutline"></ion-icon>
                <ion-select v-model="selectedMetric" interface="popover" placeholder="排序方法" compareWith="id">
                  <ion-select-option v-for="metric in getAvailableMetrics()" :key="metric.id" :value="metric">
                    {{ metric.label }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col class="ion-text-right" size-xs="3">
              <ion-button size="small" color="dark" fill="outline" @click="setSelectingComparingPlaces(true)">比較</ion-button>
            </ion-col>
          </ion-row>
          <ion-item v-show="isSelectingComparingPlaces">
            <ion-button slot="start" size="small" color="tertiary" @click="comparePlaces()" v-show="getComparingPlaces().length > 0">開始比較</ion-button>
            <ion-label @click="isCompareListModalOpened = true">已選擇 {{ getComparingPlaces().length }} 個地點</ion-label>
            <ion-button slot="end" size="small" color="danger" fill="clear" @click="setSelectingComparingPlaces(false)">
              取消
            </ion-button>
          </ion-item>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>

      <!--
        List of places to be compared
        -->
      <ion-modal
        :is-open="isCompareListModalOpened"
        @didDismiss="isCompareListModalOpened = false"
      >
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button slot="icon-only" @click="isCompareListModalOpened = false"><ion-icon :icon="close"></ion-icon></ion-button>
            </ion-buttons>
            <ion-title>要比較的地點</ion-title>
            <ion-buttons slot="end">
              <ion-button color="tertiary" @click="comparePlaces()" v-show="getComparingPlaces().length > 0">
                開始比較
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <!--
            Places to be compared
          -->
          <ion-list>
            <ion-item v-for="place in getComparingPlaces()" :key="place.id">
              <ion-thumbnail v-if="place.mainImgLink" slot="start">
                <ion-img :src="place.mainImgLink"></ion-img>
              </ion-thumbnail>
              <ion-label class="ion-text-wrap">
                <p>{{ place["名稱"] }}</p>
              </ion-label>
              <ion-buttons slot="end">
                <ion-button @click="place.isChecked = false">
                  <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-modal>

      <ion-grid fixed>

        <!-- Loading Data -->
        <loading-skeleton v-if="loading"></loading-skeleton>

        <!-- Data loaded -->
        <div v-else>
          <ion-list>

            <ion-item v-for="place in filterPlaces(typeFilter, selectedDistrict, selectedMetric).slice(0, numOfVisibleItems)" :key="place.id" button :detail="!isSelectingComparingPlaces"
                      @click="isSelectingComparingPlaces ? place.isChecked = !place.isChecked : goToPlaceDetailPage(place.id)"
                      :class="{ 'disabled': place.permClosed }">

              <ion-checkbox mode="ios" color="dark" slot="start" v-show="isSelectingComparingPlaces"
                            :modelValue="place.isChecked"></ion-checkbox>
                            
              <ion-thumbnail v-show="!isSelectingComparingPlaces" slot="start">
                <ion-img :src="place.mainImgLink"></ion-img>

                <ion-badge color="light" class="bookmark-icon" v-if="userLoggedIn && place.likedByUser" @click.stop="updateUserLikedPlace(place, 'remove')">
                  <ion-icon :icon="heart" color="danger"></ion-icon>
                </ion-badge>
                <ion-badge color="light" class="bookmark-icon" v-if="userLoggedIn && !place.likedByUser" @click.stop="updateUserLikedPlace(place, 'add')">
                  <ion-icon :icon="heartOutline"></ion-icon>
                </ion-badge>
              </ion-thumbnail>
              
              <ion-label class="ion-text-wrap">
                <h3 style="margin-bottom: 5px">
                  {{ place["名稱"] }}
                </h3>
                <ion-badge :color="getTypeColor(place['種類'])">{{ place['種類'] }}</ion-badge>&nbsp;
                <ion-badge color="medium">{{ place["地區"] }}</ion-badge>&nbsp;
                <ion-badge color="danger" v-if="place.permClosed">{{ t('permClosed') }}</ion-badge>
                <p class="ion-text-nowrap" v-if="selectedMetric && selectedMetric.key">
                  {{ selectedMetric.name }}：{{ place[selectedMetric.key] == "" ? "-" : numberWithCommas(place[selectedMetric.key]) }}
                </p>
              </ion-label>
            </ion-item>
          </ion-list>
          
          <ion-infinite-scroll
            @ionInfinite="loadData($event)" 
            threshold="100px" 
            id="infinite-scroll"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              :loading-text="t('loadingMorePlaces')">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
         swapVerticalOutline, trashOutline, heart, heartOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonSkeletonText, IonModal, IonImg,
        IonSegment, IonSegmentButton, IonChip, IonBackButton, IonCheckbox, IonBadge,
        IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
        loadingController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import PlaceItem from "@/components/PlaceItem.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: 'PlaceListPage',
  components: { IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonCheckbox, IonBadge, IonModal, IonImg,
                IonSkeletonText, IonSegment, IonSegmentButton, IonChip, IonBackButton,
                IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption, 
                LoadingSkeleton, PlaceItem,
              },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { getTypeColor, sleep, numberWithCommas, getLocalizedStr, infiniteScrollLoadData, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const currUser = computed(() => store.state.user);
    const userLatLong = computed(() => store.state.userLatLong);
    const loading = computed(() => store.state.loadingAppPublicData);
    const places = computed(() => store.state.places);
    const placeTypes = computed(() => store.state.placeTypes);

    const searchBarRef = ref(null);
    const showSearchBar = ref(false);
    const searchKeyword = ref("");
    const numOfVisibleItems = ref(20);
    const typeFilter = ref("全部");
    const selectedDistrict = ref("all");

    const isSelectingComparingPlaces = computed(() => store.state.isSelectingComparingPlaces);
    const isCompareListModalOpened = ref(false);

    const sortableMetrics = [
      //{ key: 'overallRating', label: '綜合評分 (高至低)', name: '綜合評分' },
      { id: 'avgSMPRatingDesc', key: 'avgSMPRating', label: '綜合評分 (高至低)', name: '綜合評分' },
      { id: 'avgSMPRatingAsc', key: 'avgSMPRating', label: '綜合評分 (低至高)', name: '綜合評分', order: 'asc' },
      { id: 'distance', key: 'distance', label: '與地點距離 (近至遠)', name: '距離' },
      { id: 'fbLikes', key: 'fbLikes', label: 'Facebook 讚好數 (高至低)', name: 'Facebook 讚好數' },
      { id: 'fbRating', key: 'fbRating', label: 'Facebook 評分 (高至低)', name: 'Facebook 評分' },
      { id: 'igFollowers', key: 'igFollowers', label: 'Instagram 追蹤人數 (高至低)', name: 'Instagram 追蹤人數' },
      { id: 'gmRating', key: 'gmRating', label: 'Google Maps 評分 (高至低)', name: 'Google Maps 評分' },
    ]
    const selectedMetric = ref(sortableMetrics[0]);

    const getAvailableMetrics = () => {
      return userLatLong.value == null ? sortableMetrics.filter(m => m.key != 'distance') : sortableMetrics;
    }

    const onClickSearchIcon = () => {
      showSearchBar.value = true;
      setTimeout(() => {
        searchBarRef.value.$el.setFocus();
      }, 100);
    }

    const filterPlaces = (typeFilter, selectedDistrict, metric) => {
      const resultPlaces = places.value.filter(place => {
        return (searchKeyword.value == "" || place['名稱'].toLowerCase().includes(searchKeyword.value.toLowerCase())) && 
              (typeFilter == '全部' || place['種類'].includes(typeFilter)) &&
                (selectedDistrict == 'all' || place['地區'] == selectedDistrict);
      });
      if (metric && metric.key) {
        if (metric.key == 'distance') {
          resultPlaces.sort((a, b) => (Number(a.distanceVal) < Number(b.distanceVal) ? -1 : (Number(a.distanceVal) > Number(b.distanceVal) ? 1 : 0)));
        } else {
          if (metric.order == 'asc') { // Ascending order
            resultPlaces.sort((a, b) => {
              if (a[metric.key] == "") return 1;
              if (b[metric.key] == "") return -1; // put no rating places to back
              const numberA = Number(a[metric.key]), numberB = Number(b[metric.key]);
              return numberA < numberB ? -1 : (numberA > numberB ? 1 : 0);
            });
          } else { // Descending order
            resultPlaces.sort((a, b) => (Number(a[metric.key]) > Number(b[metric.key]) ? -1 : (Number(a[metric.key]) < Number(b[metric.key]) ? 1 : 0)));
          }
        }
      }
      return resultPlaces;
    }
    const filteredDistricts = (typeFilter) => {
      const tmpObj = {};
      filterPlaces(typeFilter, 'all').forEach(place => {
        const district = place['地區'];
        if (district && district != '-') {
          tmpObj[district] = (tmpObj[district] || 0) + 1;
        }
      });
      return Object.keys(tmpObj).map(district => {
        return { name: district, numOfInfo: tmpObj[district] };
      });
    }
    
    const loadData = (ev) => {
      const filteredPlaces = filterPlaces(typeFilter.value, selectedDistrict.value);
      infiniteScrollLoadData(ev, numOfVisibleItems, filteredPlaces);
    }

    const setSelectingComparingPlaces = (isSelecting) => {
      store.commit('setSelectingComparingPlaces', isSelecting);
    }

    const getComparingPlaces = () => {
      return places.value.filter(p => p.isChecked);
    }

    const comparePlaces = async () => {
      isCompareListModalOpened.value = false;

      const loading = await loadingController.create({});
      await loading.present();
      await sleep(1);
      loading.dismiss();

      const placeIds = getComparingPlaces().map(p => p.id).join(",");
      router.replace({ path: '/compare', query: { placeIds } });
    }

    const goToPlaceDetailPage = (placeId) => {
      router.push(`/places/${placeId}`);
    }

    const updateUserLikedPlace = async (place, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateUserLikedPlace', { place, id: place.id, action });
      await sleep(1); // 1 second
      loading.dismiss();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, getLocalizedStr,
      onClickSearchIcon, getAvailableMetrics,
      loadData, filterPlaces, filteredDistricts,
      getComparingPlaces, comparePlaces, getTypeColor, numberWithCommas,
      setSelectingComparingPlaces,
      goToPlaceDetailPage,
      updateUserLikedPlace,

      // icons
      add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
      swapVerticalOutline, trashOutline, heart, heartOutline,

      // variables
      loading, currUser, userLoggedIn,
      places, placeTypes,
      typeFilter, selectedDistrict, numOfVisibleItems,
      searchBarRef, showSearchBar, searchKeyword,
      sortableMetrics, selectedMetric,
      isSelectingComparingPlaces, isCompareListModalOpened,
    }
  }
}
</script>

<style scoped>
  .bookmark-icon {
    position: absolute;
    top: 5px;
    left: 5px;
   }
</style>